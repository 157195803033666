const HomeMasterURL = "https://www.bharatabroad.com/api/homemaster";
const HomeEventURL = "https://www.bharatabroad.com/api/events/data";
const HomeSportURL = "https://www.bharatabroad.com/api/sports";
const TechURL = "https://www.bharatabroad.com/api/tech";
const HealthMasterURL = "https://www.bharatabroad.com/api/healthmaster";

const ExpressURL = {
  HomeMasterURL,
  HomeEventURL,
  HomeSportURL,
  TechURL,
  HealthMasterURL,
};

export default ExpressURL;
